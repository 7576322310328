<template>
  <div class="w-full flex flex-1 flex-col">
    <!-- Header -->
    <div class="min-h-40 flex justify-between items-center pb-8 border-b-2 border-gray-200">
      <h3 class="text-base md:text-20 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
        Profile information
      </h3>
    </div>

    <div class="w-full flex flex-1 flex-col overflow-y-auto">
      <div class="w-full flex my-16 md:py-32">
        <AppUserAvatar
          class="hidden md:block pr-32"
          :with-full-name="false"
          size-classes="min-w-128 w-128 h-128"
          text-class="text-24"
        />

        <div class="w-full">
          <p class="text-18 md:text-20 font-bold font-heading text-gray-900 whitespace-normal">
            {{ userInfo.firstName }} {{ userInfo.lastName }}
          </p>

          <div class="w-full mt-16">
            <p class="text-14 md:text-base font-medium text-gray-800 whitespace-normal">
              Email: {{ userInfo.email }}
            </p>
          </div>

          <div class="w-full flex justify-center md:justify-start flex-wrap mt-24">
            <!-- <AppButton
              :to="{ name: routeNames.editProfile }"
              variant="primary"
              size="standart-sm"
              class="mr-2 mb-2"
            >
              Edit information
              <i class="icon-edit-pencil text-xs ml-2" />
            </AppButton> -->

            <AppButton
              :to="{ name: routeNames.changePasswordProfile }"
              variant="primary"
              size="standart-sm"
            >
              Change password
              <i class="icon-lock-closed text-12 ml-4" />
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'

import { routeNames } from '@/router'
import { store } from '@/store'

import AppButton from '@/components/stateless/AppButton.vue'
import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue'

export default defineComponent({
  name: 'Profile',

  components: {
    AppButton,
    AppUserAvatar
  },

  setup () {
    const loading = ref(true)

    const userInfo = computed(() => {
      return {
        firstName: store.state.authStore.user?.firstName || '-',
        lastName: store.state.authStore.user?.lastName || '-',
        email: store.state.authStore.user?.email || '-'
      }
    })

    return {
      routeNames,
      loading,
      userInfo
    }
  }
})
</script>
