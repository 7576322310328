<template>
  <div class="w-auto flex items-center group">
    <div
      class="
      flex justify-center items-center
      bg-blue-600 text-white rounded-full"
      :class="[sizeClasses, textClass]"
    >
      <span class="text-white">{{ userInitials }}</span>
    </div>

    <p
      v-if="withFullName"
      class="
        leading-none text-gray-800 text-14 break-normal text-left ml-12
        transition duration-200 group-hover:text-blue-600"
    >
      {{ userFullName }}
    </p>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'

import { store } from '@/store'

export default defineComponent({
  name: 'AppUserAvatar',

  props: {
    withFullName: {
      type: Boolean,
      default: true
    },
    sizeClasses: {
      type: String,
      default: 'min-w-32 w-32 min-h-32 h-32'
    },
    textClass: {
      type: String,
      default: 'text-14'
    }
  },

  setup () {
    const userInitials = computed(() => {
      const firstNameInitial = store.state.authStore.user?.firstName?.[0].toUpperCase() || ''
      const lastNameInitial = store.state.authStore.user?.lastName?.[0].toUpperCase() || ''

      return `${firstNameInitial}${lastNameInitial}`
    })

    const userFullName = computed(() => {
      return store.getters['authStore/userFullName']
    })

    return {
      userInitials,
      userFullName
    }
  }
})
</script>
